import React from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import type { NotifyOption } from './types';
import { IconButton } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { Close } from '@mui/icons-material';
import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { vehicleAlertListAtom } from './vehicle';
import { settingsAtom } from '@data/settings/states';

// Header の通知リスト最大数
export const MAX_ALERT_NUM = 100;

export const hasAlertAtom = atom({
  key: 'hasAlertAtom',
  default: false,
});

export const useNotification = () => {
  const setHasAlert = useSetRecoilState(hasAlertAtom);
  const resetVehicleAlertList = useResetRecoilState(vehicleAlertListAtom);
  const settings = useRecoilValue(settingsAtom);

  const notify = useCallback(
    (options: NotifyOption) => {
      enqueueSnackbar({
        ...options,
        autoHideDuration: 10000,
        persist: !settings.alert.autoClose,
        message: (
          <div data-testid={options.testIdAttrs?.wrapperTestId}>
            {options.message}
          </div>
        ),
        action: (key) => (
          <IconButton
            data-key={key}
            size="small"
            color="inherit"
            onClick={() => {
              if (!isNullOrUndefined(options.closeFunction)) {
                options.closeFunction();
              }
              closeSnackbar(key);
            }}
            data-testid={options.testIdAttrs?.closeButtonTestId}
          >
            <Close fontSize="small" />
          </IconButton>
        ),
      });
    },
    [settings.alert.autoClose],
  );

  const notifyInfo = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'info' });
    },
    [notify],
  );

  const notifySuccess = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'success' });
    },
    [notify],
  );

  const notifyWarning = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'warning' });
    },
    [notify],
  );

  const notifyError = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'error' });
    },
    [notify],
  );

  /*=========================
    Alert はサウンドを再生する
  ==========================*/

  /**
   * data-alert 属性をもつ要素があるかどうかをチェックし、ない場合は setAlert = false とする
   */
  const checkAlertElements = useCallback(() => {
    const containerElement = document.querySelector(
      '.notistack-SnackbarContainer',
    );
    if (isNullOrUndefined(containerElement)) {
      setHasAlert(false);
      return;
    }
    const alertElements = containerElement.querySelectorAll('[data-alert]');
    setHasAlert(alertElements.length > 0);
  }, [setHasAlert]);

  const notifyAlert = useCallback(
    function <T extends NotifyOption>(options: T) {
      notify({
        ...options,
        onEnter: () => {
          if (!isNullOrUndefined(options.enterFunction)) {
            options.enterFunction();
          }
          setHasAlert(true);
        },
        onExited: () => {
          if (!isNullOrUndefined(options.exitedFunction)) {
            options.exitedFunction();
          }
          setTimeout(() => {
            checkAlertElements();
          }, 500);
        },
      });
    },
    [notify, checkAlertElements, setHasAlert],
  );

  const clearAllNotification = useCallback(() => {
    closeSnackbar();
    setHasAlert(false);
    resetVehicleAlertList();
  }, [setHasAlert, resetVehicleAlertList]);

  return {
    notify,
    notifyInfo,
    notifySuccess,
    notifyWarning,
    notifyError,
    notifyAlert,
    clearAllNotification,
  };
};
